.portfolio__container {
    padding: 30px;
    min-width: 100%;
    font-family: var(--body-font);
    color: var(--text-color);
    text-align: center;
}

.portfolio__container h1 {
    display: flex;
    justify-content: center;
    font-size: 3rem;
    font-weight: 700;
    color: var(--title-color);
}

.portfolio__container h1:hover {
    color: var(--first-color);
}

.portfolio__container p {
    font-size: 16px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.portfolio__container-filter {
    display: inline-block;
    vertical-align: center;
    margin: 0 auto;
    border-radius: 25px;
    border: 1px dashed var(--first-color);
}

li {
    font-size: 16px;
    display: inline-block;
    cursor: pointer;
    position: relative;
    color: var(--title-color);
    padding: 12px 20px;
    overflow: hidden;
    transition: all 0.5s ease;
}



.subactive {
    background: var(--first-color);
    color: #ffffff;
    font-weight: 600;
    border-radius: 25px;
}

.portfolio__container-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
    gap: 1rem;
    margin-top: 10px;
}

.portfolio__container-cards_item {
    height: 366px;
    cursor: pointer;
    position: relative;
    margin-top: 2rem;
}

.portfolio__container-cards_item:hover .overlay {
    opacity: 1;
}

.portfolio__container-cards_item-image  {
    height: 100%;
}

a {
    height: 100%;
}

img {
    height: inherit;
    object-fit: contain;
    width: 100%;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
    background-color: var(--first-color);
}

.overlay div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.overlay p {
    color: var(--body-color);
    margin: 0;
    font-size: 3rem;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    animation: typingEffect 1s steps(30, end);
}

.overlay button {
    cursor: pointer;
    margin-top: 16px;
    margin-left: 1.5rem;
    padding: 8px 40px;
    font-size: 1.2rem;
    background: transparent;
    color: var(--body-color);
    border: 1.5px solid var(--body-color);
    border-radius: 4px;
    font-weight: 500;
}


@media screen and (min-width: 1900px) {
    .portfolio__container {
        margin-top: 3rem;
    }


    .portfolio__container-cards {
        grid-template-columns: repeat(4, 1fr);
        margin-top: 4rem;
    }

    .portfolio__container-cards_item {
        margin-top: 1rem;
    }
}




@media only screen and (max-width: 480px) {
    .portfolio__container-filter li {
        font-size: 11px;
    }

    .portfolio__container-cards {
        grid-template-columns: repeat(1, 1fr);
    }

    .portfolio__container-cards_item {
        margin-bottom: -5rem;
    }
}

@media only screen and (min-width: 481px) and (max-width: 821px) {
    .portfolio__container-filter li {
        font-size: 13px;
    }

    .portfolio__container-cards {
        grid-template-columns: repeat(2, 1fr);
    }

    .portfolio__container-cards_item {
        margin-bottom: -4rem;
    }

    .overlay p {
        font-size: 2rem;
    }

    .overlay button {
        font-size: 1rem;
        padding: 5px 15px;
    }
}


@keyframes typingEffect {
    from {
        width: 0;
    }

    to {
        width: 100%
    }
}

