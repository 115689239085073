.home {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.home__content {
    display: flex;
    justify-content: center;
}

.home__content h1 {
    color: var(--title-color);
    margin: 0;
    font-weight: 500;
    font-size: 6rem;
    line-height: 8rem;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

h1 span {
    transition: 0.5s ease-out;
}
h1:hover span:nth-child(1) {
    margin-right: 5px;
}
h1:hover span:nth-child(1):after {
    content: "";
}
h1:hover span:nth-child(2) {
    margin-left: 30px;
}
h1:hover span {
    color: var(--title-color);
    text-shadow: 0 0 20px #ffffff, 0 0 20px var(--first-color), 0 0 40px var(--first-color);
}


.home__contact {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

@media only screen and (max-width: 550px) {
    .home__content h1 {
        font-size: 3rem;
        line-height: 5rem;
    }

}

@media only screen and (min-width: 551px) and (max-width: 768px) {
    .home__content h1 {
        font-size: 4rem;
        line-height: 6rem;
    }
}

@media only screen and (min-width: 769px) and (max-width: 990px) {
    .home__content h1 {
        font-size: 5rem;
        line-height: 7rem;
    }

}
