.contact__content {
    margin: 4rem 11rem;
    font-family: var(--body-font);
    color: var(--text-color);
}


.contact__content-header {
    display: flex;
    flex-direction: column;
}

h1 {
    color: var(--title-color);
    display: inline-block;
    font-size: 5rem;
    font-weight: 700;
    line-height: 1em;
    letter-spacing: 0.5px;
    position: relative;
    padding: 5px 10px;
}

.projectIdea {
    border-bottom: 2px solid var(--first-color-lighter);
    max-width: 70%;
    color: var(--text-color);
    left: 18rem;
    margin-bottom: 3rem;
}

h1:hover {
    color: var(--first-color);
}

.contact__body {
    display: flex;
    flex-direction: row;
    max-width: 1000px;

}

.contact__content-form {
    margin-top: 30px;
    width: 138%;
}

.contact__content-formWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    margin-bottom: 30px;
}

input,
textarea {
    width: 100%;
    font-family: var(--body-font);
    color: var(--title-color);
    background-color: transparent;
    border: none;
    border-bottom: 2px solid var(--first-color-lighter) ;
    padding: 16px 12px;
    transition: all 0.5s ease;
    position: relative;
    outline: none;

}

input[type=text]:focus,
textarea[type=text]:focus {
    background-color: var(--body-color);
}

textarea {
    padding: 0 12px;
}


input::placeholder,
textarea::placeholder {
   color: var(--text-color);
    font-size: 14px;
}

.projectLabel li{
   display: inline-block;
  font-size: 14px;
    color: var(--text-color-light);
    margin: 0.2rem 0 2rem;
    cursor: unset;
}

.contact__content-form_rightSide {
    font-family: var(--body-font);
    flex: 0.6;
    position: relative;
    left: 26.4rem;
    margin-top: 3rem;

}

h2 {
    font-size: 16px;
    margin-bottom: 1.5rem;
}

.startDateLabel {
    font-size: 14px;
    padding: 0 12px;
    color: var(--text-color);
    margin-bottom: 0.5rem ;
}

.newDate {
    display: flex;
    flex-direction: column;
    margin-bottom: 3.5rem;
}

.inputStartDate {
    max-width: 50%;
    color: var(--text-color);
}

input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(50%);
}



.contact__content-form_rightSide li {
    display: list-item;
    list-style: inside;
    font-size: 12px;
    margin: -1.2rem;
    color: var(--text-color);
    cursor: unset;
}

.contact__contacts {

    justify-content: center;
    padding-top: 5rem;
    font-size: 14px;
}

.phone,
.email
 {
    color: var(--first-color);
    font-size: 25px;
    margin-right: 1rem;
}

p {
    display: flex;
    align-content: center;
    margin-bottom: 1rem;
}


.modal {
    font-size: 12px;
    background-color: var(--body-color);
    width: 610px;
    height: 250px;
    border-radius: 18px;
    box-shadow: 0 0 50px var(--text-color-light);
    transition: all 0.5s ease-in-out;

}

.modal:hover {
    box-shadow: 0 0 50px var(--first-color-lighter);
}

.modal > .close {
    font-family: var(--body-font);
    height: 35px;
    line-height: 22px;
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 4px 8px;
    right: 20px;
    top: 15px;
    font-size: 24px;
    background: var(--body-color);
    color: var(--title-color);
    border: none;
}

.modal > .close:hover {
    color: var(--first-color);
    transform: scale(1.3);
}

.message {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 8rem;
}

.message h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--body-font);
    font-weight: 600;
    font-size: 22px;
    color: var(--title-color);
}

.message h2:hover {
    color: var(--first-color);
}


@media screen and (min-width: 1900px) {
    .contact__body {
        margin-left: 20rem;
        max-width: 100%;

    }
    .contact__content-header h1 {
      margin-left: 20rem;
    }
}


@media only screen and (max-width: 1260px) {
    .contact__content-form {
        width: 100%;
    }

    .projectLabel li{
        font-size: 12px;
        padding: 10px 3px;
        margin: 3px;
    }

    .contact__content-form_rightSide {
       left: 8rem;
    }

    .projectIdea {
        left: 10rem;
    }
}

@media only screen and (max-width: 874px) {
    .contact__content {
        margin: 7rem 0 0 10rem;
    }

    .projectIdea {
      left: 3rem;
    }

    .contact__content-form {
        width: 136%;
    }

    .contact__content-formWrapper {
        grid-template-columns: repeat(1, 1fr);
    }

    .contact__content-header h1 {
        font-size: 4rem;
    }

    .inputStartDate {
        max-width: 100%;

    }

    .contact__content-form_rightSide {
        display: none;

    }

}

@media only screen and (max-width: 560px) {
    .contact__content-form {
        width: 100%;
    }

    .contact__content {
        margin: 3rem
    }

    .contact__body {
        width: 300px;
    }

    .projectIdea {
        display: none;
    }

    .contact__content-header h1 {
        font-size: 3rem;
    }

}


