.navbar__container {
    width: 100%;
    align-items: center;
    height: auto;
    padding: 0 20px;
    flex: 1;
}

.navbar {
    font-family: var(--body-font);
    background-color: var(--body-color);
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    padding: 0 10rem;
    position: sticky;
    z-index: 1;

}

.navbar_icon {
    font-size: 45px;
    color: var(--title-color);
    cursor: pointer;
}

.navbar_icon:hover {
    color: var(--first-color);
}

.navbar__container-menu {
    display: flex;
    list-style: none;
    text-align: center;
}



.nav-icon {
    font-size: 30px;
    display: none;
}

.navbar__container-menu-item {
    line-height: 40px;
    margin-right: 3.5rem;
    font-size: 1.4rem;
}

.navbar__container-menu-item::after {
    content: '';
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: all 0.5s ease;
}


.navbar__container-menu-item:hover::after {
    width: 100%;
    background: var(--first-color-lighter);
}

.navbar__container-menu-item_link {
    color: var(--first-color);
    text-decoration: none;
    padding: 0.5rem;
    font-size: 1.3rem;
    cursor: pointer;
}



@media only screen and (max-width: 1024px) {
    .navbar__container-menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        position: absolute;
        top: 80px;
        left: -110%;
        transition: all 0.5s ease;
    }

    .navbar__container-menu.active {
        background: var(--body-color);
        left: 0;
        opacity: 1;
    }

    .navbar__container-menu-item {
        border-bottom: 0.5px solid var(--first-color-lighter);
        margin: 0;
        padding: 20px 0;
    }

    .nav-icon {
        display: block;
        color: var(--first-color);
        cursor: pointer;
        margin-left: 20px;
        margin-top: 10px;
        margin-right: -4rem;
    }

    .navbar_icon {
     margin-left: -7rem;
    }

    .navbar__container-menu-item:hover::after {
        width: 0;
    }

}