.notFound {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: var(--body-font);
}

.blob {
    max-width: 600px;
}


.animal {
    max-width: 340px;
    position: absolute;
    top: 50px;
    rotate: 10deg;
}

.notFound_content {
    display: inline-flex;
    flex-direction: column;
    position: absolute;
    color: var(--title-color);
    margin-top: 30rem;
}

.notFound_content h1 {
    font-size: 18rem;

}

.notFound_content h1:hover {
    color: sienna;
}

.notFound_content p {
    font-size: 25px;
    padding-left: 8.8rem;
}
