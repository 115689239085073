.skills {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    margin-top: 1rem;
}

.skills__header-content {
    font-family: var(--body-font);
    color: var(--text-color);
}

.skills__header-content h1 {
    display: flex;
    justify-content: center;
    font-size: 3rem;
    font-weight: 700;
    color: var(--title-color);
}

.skills__header-content h1:hover {
    color: var(--first-color);
}

.skills__header-content p {
    font-size: 16px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
}

.skills__main {
    display: flex;
    flex-direction: row;
   margin: 0 0 0 12rem;
    font-family: var(--body-font);
    color: var(--text-color);

}

.skills__main p {
    max-width: 40%;
    font-size: 16px;
    padding: 4rem;
    margin-top: 4rem;

}

.tagcloud {
    display: inline-block;
    font-size: 15.2px;
    position: relative;
    left: 5rem;
    bottom: 4.5rem;
    z-index: 99;
}

.tagcloud--item {
    color: var(--first-color);
}

.tagcloud--item:hover {
    color: var(--first-color-lighter);
}

@media screen and (min-width: 1900px) {
    .skills {
        margin-top: 3rem;
    }

    .skills__main {
        display: flex;
        justify-content: center;
        margin: 0;
    }
}

@media only screen and (max-width: 1200px) {
    .tagcloud {
        left: 4rem;
    }
}


@media only screen and (max-width: 1050px) {
    .tagcloud {
        left: 0rem;
    }

    .skills__main {
        margin: 0 0 0 6rem;

    }
}



@media only screen and (max-width: 967px) {
    .tagcloud {
        left: 3.5rem;
    }

    .skills__main {
       flex-direction: column;
    }

    .skills__main p {
        max-width: 100%;
        padding: 2rem;
        margin-top: -3rem;
    }

    .socialMedia__container {
       display: none;
    }
}


@media only screen and (max-width: 512px) {
    .tagcloud {
        display: none;
    }

    .skills__main p {
        padding: 5px 0 40px 0;
        margin: -3rem 5rem 0 0;
    }

    .socialMedia__container {
        display: none;
    }
}


