.about {
    min-width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 20px;
    margin-top: 1rem;
}

.about__content {
   font-family: var(--body-font);
    color: var(--text-color);
}


.about__content-header h1 {
    display: flex;
    justify-content: center;
    font-size: 3rem;
    font-weight: 700;
    color: var(--title-color);
}

.about__content-header h1:hover {
    color: var(--first-color);
}

.about__content-header p {
    font-size: 16px;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
}

.about__content-img {
    max-width: 450px;
   justify-content: center;
    align-self: center;
    margin: 2rem 0;
}


.about__content-data {
    display: flex;
   flex-direction: row;
    margin: 1.5rem 0 2rem 13rem;
}

.about__content-description  {
    font-size: 16px;
  justify-content: space-between;
    padding: 4rem;
}

p {
    white-space: normal;
}

p span {
    color: var(--first-color);
}


.dashboard {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    max-width: 45%;
}

.about__content-info {
    display: flex;
    justify-content: space-evenly;
    margin-top: -2rem;
    margin-bottom: 3.5rem;
}



.about__content-infoTitle, .about__content-infoName {
    display: block;
    text-align: center;
    margin-bottom: .5rem;
}

.about__content-infoTitle {
    font-size: 25px;
    color: var(--title-color);
    font-weight: bold;
}

.about__content-infoTitle:hover {
    color: var(--first-color);
}

.about-btn .resume {
    background: var(--first-color);
    color: #ffffff;
}

.about-btn .resume:hover {
    background-color: transparent;
    color: var(--first-color-alt);
}


@media screen and (min-width: 1900px) {
    .about {
        margin-top: 3rem;
    }

    .about__content-data {
        display: flex;
        justify-content: center;
    }
}


@media only screen and (max-width: 1024px) {
    .about__content-img {
        max-width: 400px;
    }

    .about__content-data {
        display: flex;
        flex-direction: row;
        margin: .5rem 0 2rem 2rem;
    }
}

@media only screen and (max-width: 768px) {
    .about__content-img {
        width: 350px;
        margin: 2rem 0;
    }

    .about__content-data {
        flex-direction: column;
        margin:  0 5rem;
    }

    .about__content-description {
        font-size: 16px;
        justify-content: space-between;
        padding: 0 3rem 5rem;
    }

    .dashboard {
        max-width: 100%;
    }

    .about__content-info {
        display: flex;
        justify-content: space-evenly;
    }
}


@media only screen and (max-width: 744px) {
    .socialMedia__container {
        display: none;
    }
}

@media only screen and (max-width: 375px) {
    .about__content-data {
        margin: 0;
    }

    .dashboard {
        font-size: 14px;
        width: 100%;

    }

    .about__content-infoTitle {
        font-size: 20px;
    }

}
